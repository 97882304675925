import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.css";

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path);

  return (
    <nav className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {paths.map((path, index) => {
          const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
          const isLast = index === paths.length - 1;
          return (
            <li
              className={`breadcrumbs-item ${isLast ? "active" : ""}`}
              key={index}
            >
              {isLast ? (
                <span>{path}</span>
              ) : (
                <Link to={routeTo} className="breadcrumbs-link">
                  {path}
                </Link>
              )}
              {!isLast && <span className="breadcrumbs-separator"> / </span>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
