import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./pages/Landing";
import Assessments from "./pages/Assessments";
import Applicants from "./pages/Applicants";
import Events from "./pages/Events";
import Breadcrumbs from "./components/Breadcrumbs";
import Questions from "./pages/Questions";
import Logout from "./components/Logout";

function AppContent() {
  const location = useLocation();
  const showBreadcrumbs = location.pathname !== "/";

  return (
    <>
      {showBreadcrumbs && <Logout />}
      {showBreadcrumbs && <Breadcrumbs />}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/assessments" element={<Assessments />} />
        <Route path="/assessments/applicants" element={<Applicants />} />
        <Route
          path="/assessments/applicants/questions"
          element={<Questions />}
        />
        <Route
          path="/assessments/applicants/questions/events"
          element={<Events />}
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
