import React, { useEffect, useState } from "react";
import axiosInstance from "../apiService/axiosInstance";
import { formatDateToLocal } from "../utils/utility";
import "./Events.css";

function Events() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(50);
  const clientAssessmentId = localStorage.getItem("assessmentId");
  const clientApplicantId = localStorage.getItem("clientApplicantId");
  const clientVideoId = localStorage.getItem("clientVideoId");

  const fetchEvents = async (page = 1, limit = 50) => {
    try {
      const authData = JSON.parse(localStorage.getItem("authData"));
      const response = await axiosInstance.get("/event/getEventByQuestionId", {
        params: {
          companyId: authData.companyId,
          clientAssessmentId: clientAssessmentId,
          clientApplicantId: clientApplicantId,
          clientVideoId: clientVideoId,
          page,
          limit,
        },
        headers: {
          authorization: `${authData.token}`,
        },
      });
      setEvents(response.data.data || []);
      setTotalPages(response.data.totalPages || 1);
      setError("");
    } catch (error) {
      setError("Failed to fetch events. Please try again later.");
    }
  };

  useEffect(() => {
    fetchEvents(currentPage, limit);
  }, [currentPage, limit]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10)); // Corrected base value
    setCurrentPage(1); // Reset to the first page when the limit changes
  };

  return (
    <div className="events-container">
      {error && <p className="error-message">{error}</p>}
      <label className="items-per-page-label">
        Items per page:
        <select
          value={limit}
          onChange={handleLimitChange}
          className="items-per-page-select"
        >
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
      </label>
      <div className="events-list">
        {events.map((event) => (
          <div className="event-card" key={event.id}>
            <p className="event-date">{formatDateToLocal(event.createdAt)}</p>
            <p className="event-name">{event.eventName}</p>
            <p className="event-function">{event.functionName}</p>
          </div>
        ))}
      </div>
      <div className="pagination-controls">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Events;
