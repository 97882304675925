import React, { useState } from "react";
import axiosInstance from "../apiService/axiosInstance";
import './Register.css';

const Register = ({ goToLogin }) => {
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault(); // Prevents the default form submission

    // Validation for companyName, username, and password length
    if (
      !companyName ||
      companyName.length < 3 ||
      companyName.length > 50 ||
      username.length < 3 ||
      username.length > 16 ||
      password.length < 3 ||
      password.length > 16
    ) {
      setError(
        "Company name must be between 3 to 50 characters. Username and password must be between 3 to 16 characters."
      );
      return;
    }

    try {
      // Example Axios POST request using axiosInstance for registration
      const response = await axiosInstance.post("/company/register", {
        companyName,
        username,
        password,
      });

      // Clear error state if any
      setError("");
      goToLogin();
    } catch (error) {
      // Handle error responses from the server
      console.error("Registration error:", error);
      setError("Registration failed. Please try again later.");
    }
  };

  return (
    <div className="register-container">
      <h2 className="register-title">Register</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleRegister} className="register-form">
        <input
          type="text"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
          className="register-input"
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="register-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="register-input"
        />
        <button type="submit" className="register-button">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
