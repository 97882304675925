import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../apiService/axiosInstance";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevents the default form submission

    // Validation for username and password length
    if (
      username.length < 3 ||
      username.length > 16 ||
      password.length < 3 ||
      password.length > 16
    ) {
      setError("Username and password must be between 3 to 16 characters.");
      return;
    }

    try {
      // Example Axios POST request using axiosInstance
      const response = await axiosInstance.post("/company/login", {
        username,
        password,
      });

      localStorage.setItem("authData", JSON.stringify(response.data));

      // Clear error state if any
      setError("");

      navigate(`/assessments`);
    } catch (error) {
      // Handle error responses from the server
      console.error("Login error:", error);
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="login-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
        />
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
