import React, { useEffect, useState } from "react";
import axiosInstance from "../apiService/axiosInstance";
import { useNavigate } from "react-router-dom";
import "./Applicants.css";

function Applicants() {
  const [applicants, setApplicants] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const authData = JSON.parse(localStorage.getItem("authData"));
  const assessmentID = localStorage.getItem("assessmentId");

  const navigate = useNavigate();

  const navigateToEvents = (clientApplicantId) => {
    localStorage.setItem("clientApplicantId", clientApplicantId);
    navigate(`/assessments/applicants/questions`);
  };

  const fetchApplicants = async (page = 1, limit = 10, searchTerm = "") => {
    try {
      const response = await axiosInstance.get(
        "/event/getApplicantByAssessmentId",
        {
          params: {
            companyId: authData.companyId,
            clientAssessmentId: assessmentID,
            page,
            limit,
            searchTerm, // Include searchTerm in API request
          },
          headers: {
            authorization: `${authData.token}`,
          },
        }
      );
      setApplicants(response.data.data || []);
      setTotalPages(response.data.totalPages || 1);
      setError("");
    } catch (error) {
      setError("Failed to fetch applicants. Please try again later.");
    }
  };

  useEffect(() => {
    fetchApplicants(currentPage, limit, searchTerm);
  }, [currentPage, limit, searchTerm]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to the first page when the limit changes
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search term changes
  };

  return (
    <div className="applicants-container">
      {error && <p className="error-message">{error}</p>}
      <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search applicants..."
          className="search-input"
        />
      </div>
      <label className="items-per-page-label">
        Items per page:
        <select
          value={limit}
          onChange={handleLimitChange}
          className="items-per-page-select"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
      </label>
      <div className="applicants-list">
        {applicants.map((applicant) => (
          <div
            className="applicant-card"
            key={applicant.id}
            onClick={() => navigateToEvents(applicant.clientApplicantId)}
          >
            <p className="applicant-id">
              ApplicantId: {applicant.clientApplicantId}
            </p>
            <p className="applicant-name">{applicant.applicantName}</p>
          </div>
        ))}
      </div>
      <div className="pagination-controls">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Applicants;
