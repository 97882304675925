import React, { useState } from "react";
import Login from "../components/Login";
import Register from "../components/Register";
import "./LandingPage.css";

const LandingPage = () => {
  const [showLogin, setShowLogin] = useState(true);

  const toggleForm = () => {
    setShowLogin((prevShowLogin) => !prevShowLogin);
  };

  return (
    <div className="landing-page">
      <h1 className="landing-title">Welcome to Evluate Tracking</h1>
      {showLogin ? (
        <div className="form-container">
          <Login />
          <p className="form-switch-text">
            Don't have an account?{" "}
            <button onClick={toggleForm} className="form-switch-button">
              Register
            </button>
          </p>
        </div>
      ) : (
        <div className="form-container">
          <Register goToLogin={() => setShowLogin(true)} />
          <p className="form-switch-text">
            Already have an account?{" "}
            <button onClick={toggleForm} className="form-switch-button">
              Login
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
