import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Logout = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    const confirmed = window.confirm("Are you sure you want to log out?");

    if (confirmed) {
      // Clear all data from local storage
      localStorage.clear();

      // Use navigate instead of history.push
      navigate("/"); // Redirect to landing page
    }
  };

  return (
    <button onClick={handleLogout} style={styles.logoutButton}>
      Logout
    </button>
  );
};

// Simple styles for the logout button
const styles = {
  logoutButton: {
    position: "absolute",
    right: 0,
    margin: "10px",
    padding: "4px 10px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    transition: "background-color 0.3s ease",
  },
};

export default Logout;
